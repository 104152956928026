import axios from 'axios';
import { Notify } from 'vant';
// import { Toast } from 'vant';


const getToken = () => {
  //...
  return localStorage.getItem('token');
  // return '8305009f-31a9-4813-acd0-0e4dea399373';
};
const reFetchToken = () => { };

if (process.env.NODE_ENV) {
  // console.log (process.env.NODE_ENV);
}
const service = axios.create({
  baseURL: '/kj',
  timeout: 600000,
  headers: { 'X-Custom-Header': 'foobar' },
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么

    const token = getToken();
    if (token) {
      config.headers['token'] = token;
    } else {
      // reFetchToken ();
    }

    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    const { data } = response;
    // console.log(data)
    if (data.code === 200) {
      return data;
    } else if (data.code === 1018) {
      Notify(data.msg)
      Promise.reject({ message: data.msg });
      window.location.href = "#/login"; //或/login
    } else if (data.code === 404) {
      Notify(data.msg)
      Promise.reject({ message: data.msg });
    } else if (data.code === 403) {
      reFetchToken();
      // 未登录
      Notify(data.msg)
      Promise.reject({ message: data.msg });
    } else if (data.code >= 500) {
      // Notify(data.msg)
      // Promise.reject({ message: data.msg });
      Notify('系统异常，请联系管理员')
      Notify('The system is abnormal.Contact the administrator')
      Promise.reject({ message: '系统异常，请联系管理员' });
      Promise.reject({ message: 'The system is abnormal.Contact the administrator' });
    }
    return response.data;
  },
  error => {
    // 对响应错误做点什么

    Notify('系统异常，请联系管理员')
    Notify('The system is abnormal.Contact the administrator')
    Promise.reject({ message: '系统异常，请联系管理员' });
    Promise.reject({ message: 'The system is abnormal.Contact the administrator' });
    return Promise.reject(error);
  }
);

export default app => {
  app.config.globalProperties.$request = service;
};

export { service };
