import { createRouter, createWebHashHistory } from 'vue-router';
import i18n from '../language/index.js';

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home', //首页
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: i18n.global.t('titleList.Home'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/login', //登陆
    name: 'login',
    component: () => import('../components/Login/index.vue'),
    meta: {
      title: i18n.global.t('titleList.login'),
    },
  },
  {
    path: '/sign', //注册
    name: 'sign',
    component: () => import('../components/sign/index.vue'),
    meta: {
      title: i18n.global.t('titleList.sign'),
    },
  },
  {
    path: '/setPass', //修改密码
    name: 'setPass',
    component: () => import('../components/setPass/index.vue'),
    meta: {
      title: i18n.global.t('titleList.forgot'),
    },
  },
  {
    path: '/kjList', //矿机列表
    name: 'kjList',
    component: () => import('../views/kjList.vue'),
    meta: {
      title: i18n.global.t('titleList.kjList'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/kjDetails', //矿机详情
    name: 'kjDetails',
    component: () => import('../views/kjDetails.vue'),
    meta: {
      title: i18n.global.t('titleList.kjDetails'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/kjOrder', //矿机订单列表
    name: 'kjOrder',
    component: () => import('../views/kjOrder.vue'),
    meta: {
      title: i18n.global.t('titleList.sign'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/kjOrderDetails', //矿机订单详情
    name: 'kjOrderDetails',
    component: () => import('../views/kjOrderDetails.vue'),
    meta: {
      title: i18n.global.t('titleList.kjOrderDetails'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/kjBuy', //购买矿机
    name: 'kjBuy',
    component: () => import('../views/kjBuy.vue'),
    meta: {
      title: i18n.global.t('titleList.kjBuy'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/more', //了解更多
    name: 'more',
    component: () => import('../components/more.vue'),
    meta: {
      title: i18n.global.t('titleList.more'),
      requireAuth: false, // 判断是否需要登录
    },
  },
  {
    path: '/my', //我的
    name: 'my',
    component: () => import('../views/My.vue'),
    meta: {
      title: i18n.global.t('titleList.my'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/purse', //钱包
    name: 'purse',
    component: () => import('../views/purse.vue'),
    meta: {
      title: i18n.global.t('titleList.purse'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/help', //帮助中心
    name: 'help',
    component: () => import('../views/help.vue'),
    meta: {
      title: i18n.global.t('titleList.help'),
      requireAuth: false, // 判断是否需要登录
    },
  },
  {
    path: '/about', //关于我们
    name: 'about',
    component: () => import('../views/about.vue'),
    meta: {
      title: i18n.global.t('titleList.about'),
      requireAuth: false, // 判断是否需要登录
    },
  },
  {
    path: '/setting', //设置
    name: 'setting',
    component: () => import('../views/setting.vue'),
    meta: {
      title: i18n.global.t('titleList.setting'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/feedBack', //我的-联系客服
    name: 'feedBack',
    component: () => import('../components/my/feedBack.vue'),
    meta: {
      title: i18n.global.t('titleList.feedBack'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/topup', //我的-钱包-充值
    name: 'topup',
    component: () => import('../views/topup.vue'),
    meta: {
      title: 'topup',
      title: i18n.global.t('titleList.topup'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/Withdrawal', //我的-钱包-提现
    name: 'Withdrawal',
    component: () => import('../views/Withdrawal.vue'),
    meta: {
      title: i18n.global.t('withdrawal.sign'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/newelectricity', //我的-电费
    name: 'newelectricity',
    component: () => import('../views/newelectricity.vue'),
    meta: {
      title: i18n.global.t('titleList.newelectricity'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/electricitytopup', //我的-电费-充值
    name: 'electricitytopup',
    component: () => import('../views/electricitytopup.vue'),
    meta: {
      title: i18n.global.t('titleList.electricitytopup'),
      requireAuth: true, // 判断是否需要登录
    },
  },
  // {
  //   path: '/millList', //矿机列表
  //   name: 'millList',
  //   component: () => import('../components/mill/millList.vue'),
  //   meta: {
  //     title: 'My list of miners',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/MentionRecord', //我的-交易记录
  //   name: 'MentionRecord',
  //   component: () => import('../views/MentionRecord.vue'),
  //   meta: {
  //     title: 'Transaction record',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/electricity', //我的-rmb交易记录
  //   name: 'electricity',
  //   component: () => import('../components/my/electricity.vue'),
  //   // component: () => import ('../components/my/feedBack.vue'),
  //   meta: {
  //     title: 'Transaction record',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/MentionRecordDetail', //我的-交易详情
  //   name: 'MentionRecordDetail',
  //   component: () => import('../views/MentionRecordDetail.vue'),
  //   meta: {
  //     title: i18n.global.t('titleList.Detail'),
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/realName', //我的-实名认证
  //   name: 'realName',
  //   component: () => import('../components/my/realName.vue'),
  //   meta: {
  //     title: 'realName',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/ethDetails', //ETH详情
  //   name: 'ethDetails',
  //   component: () => import('../components/home/ethDetails.vue'),
  //   meta: {
  //     title: '第二期详情',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/ipfsDetails', //IPFS详情
  //   name: 'ipfsDetails',
  //   component: () => import ('../components/home/ipfsDetails.vue'),
  //   meta: {
  //     title: '第四期详情',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/mill', //矿机
  //   name: 'mill',
  //   component: () => import('../views/Mill.vue'),
  //   meta: {
  //     title: '矿机',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // 每次切换路由页面滚动到顶部
  scrollBehavior(to, from, savedPosition) {
    // 滚动到顶部
    return { top: 0 }
  }
});

export default router;
